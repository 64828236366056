<template>
  <div>
    <v-card style="z-index: 10"
            tile
            color="rgb(234 234 234)"
            flat
    >
      <v-container
        style="max-width: 1400px !important; z-index: 10; width: 100%"
        class="pa-0 d-flex flex-column align-center"
      >
        <v-row no-gutters style="width: 100%" class="align-center">
          <v-col
            cols="12"
            sm="10"
            md="9"
          >
            <div style="max-width: 800px"
                 class="mx-auto d-flex flex-column"
                 :class="($vuetify.breakpoint.mdAndDown ? 'pl-4' : '') + ($vuetify.breakpoint.xs ? ' pb-4' : '')"
            >

              <div class="d-flex">

                <v-breadcrumbs :items="breadcrumb"
                               divider="/"
                               style="z-index: 10"
                               class="pa-0 d-inline-block text-truncate mt-5">
                </v-breadcrumbs>

                <v-spacer></v-spacer>

                <v-btn
                  v-if="problem && $vuetify.breakpoint.xs"
                  @click="goToPdf()"
                  style="z-index: 10; font-family: Inter, sans-serif; letter-spacing: initial"
                  class="align-center d-flex rounded-pill white--text text-none font-rubik mt-3"
                  depressed
                  color="#5890FF">
                  Veure pdf
                </v-btn>
              </div>

              <v-card
                v-if="subjectInfo"
                class="d-flex align-center justify-start pa-0 problem-card elevation-0 mx-auto mt-3"
                flat tile
                color="transparent"
                style="position: relative; width: 100%; z-index: 10; flex: 1; max-width: 800px; margin-bottom: -8px !important; margin-left: -18px !important;"
              >
                <!--           <span class="font-rubik font-weight-medium">-->
                <!--            Totes les Sèries:-->
                <!--          </span>-->
                <v-chip-group :column="$vuetify.breakpoint.xs" class="elevation-0 d-flex ml-4">
                  <router-link
                    v-for="serie in subjectInfo.series"
                    :to="`/selectivitat/${$route.params.subject_name}/examens/${$route.params.year}/${serie.month}?serie=${serie.serie}`">
                    <v-chip
                      value="serie"
                      class="rounded-lg no-background-hover font-rubik font-weight-medium px-6 text-capitalize"
                      :class="(serie.serie == query.serie ? 'white--text' : 'thin-border') + ($vuetify.breakpoint.xs ? '' : ' rounded-b-0')"
                      :style="'background: ' + (serie.serie == query.serie ? 'linear-gradient(to bottom,rgba(244,244,244,0.02),rgba(244,244,244, 0.3)),' + getColor : 'white')"
                      :ripple="false"
                      style="height: 44px; font-size: 16px; border-bottom: 0 !important;"
                    >
                      {{ serie.month }}, Sèrie {{ serie.serie }}
                    </v-chip>
                  </router-link>

                </v-chip-group>

              </v-card>

            </div>
          </v-col>

          <v-col
            v-if="!$vuetify.breakpoint.xs"
            :cols="$vuetify.breakpoint.smAndDown ? 2 : 3">
            <v-btn
              v-if="problem"
              @click="goToPdf()"
              style="z-index: 10; font-family: Inter, sans-serif; letter-spacing: initial"
              class="align-center d-flex rounded-pill white--text text-none font-rubik"
              depressed
              color="#5890FF">
              Veure pdf
            </v-btn>
          </v-col>

        </v-row>


      </v-container>
    </v-card>

    <div ref="problems" style="z-index: 10; position: relative">
      <v-container
        style="max-width: 1400px !important; z-index: 10"
        :class="$vuetify.breakpoint.xs ? 'pa-0' : ''"
      >

        <v-row
          v-if="problem"
          no-gutters
          class="pa-0"
          style="margin: auto; background-color: rgb(244, 244, 244) !important;z-index: 10; flex: 1"
        >
          <!-------------------------------------->
          <!-- Problemes Container              -->
          <!-------------------------------------->
          <v-col :cols="$vuetify.breakpoint.smAndDown ? 12 : 9"
                 class="align-center"
                 style="z-index: 2"
          >

            <div style="z-index: 99 !important; max-width: 800px; margin: auto;"
                 class="d-flex flex-column pt-4"
                 :class="$vuetify.breakpoint.xs ? 'px-2' : ''"
            >

            <span
              v-if="params"
              class="font-rubik font-weight-medium"
              style="font-size: 26px; z-index: 99 !important; color: black !important;"
            >
                {{ `Examen ${this.deApostrof(this.params.subject_name)} del ${this.params.year}` }}
            </span>

              <span
                class="font-rubik font-weight-medium blue-grey--text text--darken-3"
                style="z-index: 10 !important; font-size: 18px"
              >
                  {{ `${params.mes}, Sèrie ${query.serie}` }}
                </span>


            </div>


            <v-row style="margin: auto; flex: 1"
                   class="mt-1"
            >

              <v-col
                style="max-width: 800px; margin: auto; flex: 1"
                class="pa-0">


                <problem-card :problem="problem"
                              :selectedProblem.sync="selectedProblem"
                              :allProblemsIds="allProblemsIds"
                              class="mt-6"
                              @toLeft="toLeft"
                              @toRight="toRight"
                ></problem-card>


                <horizontal-ads-after-solution
                  v-if="problem && !loading && ($root.currentUser === null || !$root.currentUser.premium)"
                ></horizontal-ads-after-solution>

              </v-col>

            </v-row>


          </v-col>


          <!-------------------------------------->
          <!-- Right Column                     -->
          <!-------------------------------------->
          <v-col v-if="!$vuetify.breakpoint.smAndDown && !loading" cols="3" style="position: relative; z-index: 2">

            <div style="position: sticky; top: 100px; left: 0"
                 class="px-4"
            >


              <anuncis-verticals :color="getColor"
                                 :selectedProblem="selectedProblem"
              ></anuncis-verticals>

              <v-card
                v-if="false"
                class="my-2 rounded-lg"
                height="250" style="z-index: 99"
                width="300" flat color="transparent">

                <adsense ad-slot="6844584728"
                         style="display:inline-block;width:300px;height:250px;z-index: 99"
                ></adsense>

              </v-card>

            </div>
          </v-col>
        </v-row>
      </v-container>


      <div
        style="height: 200px; position: absolute; top: 0; left: 0; width: 100%; z-index: 1; opacity: 0.7;"
        class="d-flex"
        :style="`background: linear-gradient(to bottom,rgba(244,244,244,0.01),rgba(244,244,244, 1)),${getColor}`"
      >
      </div>
    </div>


    <all-problems
      v-if="problems && !loading"
      :problems="problems"
      :selectedProblem="selectedProblem"
      @fetchProblems="fetchProblems"
    ></all-problems>


    <v-dialog v-model="showAdsDialog" max-width="fit-content" content-class="elevation-0">
      <div style="position:relative;">
        <router-link to="/dossiers-selectivitat">
          <img
            :src="adsDialogImage"
            style="width: 100%; max-height: 600px; border-radius: 16px !important;"
            contain
          >
        </router-link>

        <v-icon
          @click="showAdsDialog=false"
          size="18"
          class="grey lighten-3 rounded-sm"
          style="position: absolute; right: 15px; top: 15px">{{ mdiClose() }}
        </v-icon>
      </div>
    </v-dialog>

  </div>
</template>

<script>

import {mdiClose, mdiFilePdfBox} from "/src/assets/mdi.json";
import ExamensRecomenats from "./recommended-exams/ExamensRecomenats.vue";
import AnuncisVerticals from "./ads/AnuncisVerticals.vue";
import ProblemCard from "./problem-card/ProblemCard.vue";
import HorizontalAdsAfterSolution from "./ads/HorizontalAdsAfterSolution.vue";
import AllProblems from "./all-problems/AllProblems.vue";
import Adsense from "../../../components/anuncis/Adsense.vue";

export default {
  name: 'ProblemesPerAny',
  components: {Adsense, AllProblems, HorizontalAdsAfterSolution, ProblemCard, AnuncisVerticals, ExamensRecomenats},
  metaInfo() {
    return {
      title: `${this.seoTitle}`,
      titleTemplate: '%s - examenselectivitat',
      meta: [
        {charset: 'utf-8'},
        {
          name: 'description',
          content: `Examen ${this.deApostrof(this.params.subject_name)} del ${this.params.year} amb la solució en un clic.`
        },
        {name: 'viewport', content: 'width=device-width, initial-scale=1'}
      ]
    }
  },
  mounted() {
    this.$forceUpdate();
  },
  data() {
    return {
      subject: null,
      subjectInfo: null,
      loading: true,
      url: process.env.VUE_APP_AXIOS_URI,
      problems: [],
      problem: null,
      selectedProblem: null,
      showAdsDialog: false,
      adsDialogImage: "https://examenselectivitat.cat:3000/api/images/other/apunts-dialog-diagonal.webp",
      allProblemsIds: [],
      seriesTabs: []
    }
  },
  computed: {
    seoTitle() {
      return `Examen ${this.deApostrof(this.params.subject_name)} del ${this.params.year}: ${this.params.mes} sèrie ${this.query.serie}`
    },
    params() {
      return this.$route.params
    },
    query() {
      return this.$route.query
    },
    breadcrumb() {
      return [
        {
          text: 'Assignatures',
          disabled: false,
          exact: true,
          to: '/',
        },
        {
          text: this.$route.params.subject_name,
          disabled: false,
          exact: true,
          to: '/selectivitat/' + this.$route.params.subject_name,
        },
        {
          text: this.params.year,
          disabled: true,
          to: '',
        },
      ]
    },
    getColor() {

      const colors = [
        "#FF8FB1",
        "#256EFF",
        "#FFA201",
        "#2FCA9F",
        "#C47AFF",
        "#40C4FF",
        "#A460ED",
        "#8C9EFF",
        "#2F89FC",
        "#00BFA5",
        "#ffa63c",
      ]

      return this.subjectInfo ? colors[(this.subjectInfo.subject_id) % colors.length] : 'rgba(244,244,244, 1)'
    },
  },
  methods: {
    goToPdf() {
      if (this.$vuetify.breakpoint.smAndDown) {
        const pdf = `https://examenselectivitat.cat:3000/api/pdfs/${this.subjectInfo.subject_path}/${this.$route.params.year}/Juny/Enunciat`;
        window.open(pdf);
      } else {
        this.$router.push({path: '/selectivitat/' + this.$route.params.subject_name + '/pdf/' + this.$route.params.year + '/Juny/Enunciat'})
      }
    },
    mdiFilePdfBox() {
      return mdiFilePdfBox
    },
    mdiClose() {
      return mdiClose
    },
    getTopicName() {
      return this.subjectInfo && this.subjectInfo.topic_name ? this.subjectInfo.topic_name : null
    },
    resetValues() {
      this.subjectInfo = null
      this.loading = true
      this.problems = []
      this.problem = null
      this.selectedProblem = null
    },
    fetchSubjectInfo: async function () {
      try {

        const subject_name = this.$route.params.subject_name
        const year = this.$route.params.year;
        let serie = this.$route.query.serie;

        const {data} = await this.axios.get(`/subject-info/year/${subject_name}/${year}`)
        this.subjectInfo = data

        // Set serie query if not set
        if (!serie) {
          if (this.params.mes === "Setembre") {
            const serieSet = data["series"].find(s => s["month"] === "Setembre");
            console.log("serieSet", serieSet)
            if (serieSet) {
              await this.$router.replace({query: {serie: serieSet['serie']}})
            } else {
              await this.$router.replace({query: {serie: data["series"][0]['serie']}})
            }
          } else {
            const serieJuny = data["series"].find(s => s["month"] === "Juny");
            await this.$router.replace({query: {serie: serieJuny['serie']}})
          }
        }

        this.allProblemsIds = data["series"].find(s => s['serie'] == serie)["problems_ids"];
      } catch (error) {
        if (error.response && error.response.status === 429) {
          this.$root.showCaptcha = true;
        }
      }
    },
    deApostrof(assig) {
      if (assig) {
        const lletra = assig.charAt(0).toLowerCase()
        return lletra === 'a' || lletra === 'e' || lletra === 'i' || lletra === 'o' || lletra === 'u' || lletra === 'h' ? "d'" + assig : 'de ' + assig
      }
    },
    async fetchProblems() {
      if (this.problems.length < this.allProblemsIds.length) {
        try {
          const subject_name = this.$route.params.subject_name;
          const year = this.$route.params.year;
          const serie = this.$route.query.serie;
          const offset = this.problems.length;
          // problems/year/:subject_name/:year/:serie/:offset
          const {data} = await this.axios.get(`/problems/year/${subject_name}/${year}/${serie}/${offset}`);

          this.problems = this.problems.concat(...data["problems"])

          await this.setProblem()
        } catch (error) {
          console.log("error", error.response.data)
          if (error.response.data.captchaRequired) {

            // if (error && error.status === 429) {
            // Show CAPTCHA
            this.$root.showCaptcha = true;
          }
        }
      }
    },
    async setProblem() {
      const query = this.$route.query;
      // Problem on query
      if (query && query['problem']) {
        const index = this.problems.findIndex(p => p['problem_id'] === parseInt(query['problem']));
        // Found on actual list
        if (index >= 0) {
          this.problem = this.problems[index];
          this.selectedProblem = index;
        }
        // Not found in actual problems list
        else {
          await this.fetchOneProblem(query['problem']);
        }
      }
      // Default case: no selected problem
      else if (this.selectedProblem === null) {
        this.selectedProblem = 0;
        this.problem = this.problems[this.selectedProblem];
      }

    },
    async fetchOneProblem(problem_id) {
      const {data} = await this.axios.get(`/problems/${problem_id}`);
      this.problem = data
    },
    toLeft() {
      this.selectedProblem--;
      this.problem = this.problems[this.selectedProblem];
      this.$router.replace({query: {problem: this.problem["problem_id"], serie: this.$route.query.serie}})
    },
    toRight() {
      this.selectedProblem++;
      this.problem = this.problems[this.selectedProblem];
      this.$router.replace({query: {problem: this.problem["problem_id"], serie: this.$route.query.serie}})
    }
  },
  watch: {
    '$root.showCaptcha'(val) {
      if (!val)
        this.fetchProblems()
    },
    '$route.params':
      {
        immediate: true,
        async handler(val, old) {
          this.resetValues()
          await this.fetchSubjectInfo()
          await this.fetchProblems()

          document.body.scrollTop = 0
          document.documentElement.scrollTop = 0


          setTimeout(() => {
            this.loading = false
          }, 500)
        }
      },
    '$route.query':
      {
        immediate: true,
        async handler(val, old) {
          if (val["problem"]) {
            await this.setProblem()
          }

          // document.body.scrollTop = 0
          // document.documentElement.scrollTop =

        }
      },
    // selectedProblem:
    //   {
    //     immediate: true,
    //     async handler(val, old) {
    //       if (val) {
    //         // console.log("selectedProblem: ", val, "vs", this.totalProblems - 1)
    //       }
    //       // if (val && val === this.totalProblems - 1) {
    //       //   await this.fetchProblems()
    //       // }
    //     }
    //   }
  }
}
</script>

<style scoped>

.fade-enter-active {
  transition: all 0.4s ease;
}

.fade-enter {
  opacity: 0;
}


.slide-fade-enter-active {
  transition: all 1s ease;
}


.slide-fade-enter {
  transform: translateY(-30px);
  opacity: 0;
}

ins.adsbygoogle[data-ad-status="unfilled"] a {
  display: block;
}

.thin-border {
  border: 2px solid #E5E7EB !important;
}


</style>
